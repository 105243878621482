<template>
  <a-modal v-model:visible="visible" title="复制媒体账户" :width="500" wrap-class-name="edit_medai_account_modal" @cancel="clearData">
    <a-form :label-col="{ span: 8 }">
      <a-form-item label="账户ID" required="">
        <a-input :value="this.rowData.advertiserId" />
      </a-form-item>
      <a-form-item label="账户名称前缀" required="">
        <a-input v-model:value.trim="accountNamePrefix" placeholder="请输入账户名称前缀" />
      </a-form-item>
      <a-form-item label="账户起始序列号" required>
        <a-input v-model:value.trim="startSeq" placeholder="请输入账户起始序列号" />
      </a-form-item>
      <a-form-item label="复制数量" required="">
        <a-input v-model:value.trim="copyNum" placeholder="请输入复制数量" />
      </a-form-item>
      <a-form-item label="自运营报备类型 " required="">
        <a-select placeholder="自运营报备类型"  v-model:value="reportType">
          <a-select-option v-for="item in reportTypeList" :key="item.key" :value="item.key">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="clearData">取消</a-button>
      <a-button type="primary" :loading="btnLoading" @click="handleOk">确定</a-button>
    </template>
  </a-modal>
</template>

<script>
import { copyAccount,copyAccount2 } from '@/api/mediaAccount/mediaAccount';

export default {
  name: 'CopyMediaAccountModal',
  components: {},
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      reportTypeList:[
        {key:"SELF_OPERATION",name:"自运营报备"},
        {key:"INCREASE_QUANTITY",name:"走量报备"},
        {key:"EMPTY",name:"不报备"},
      ],
      reportType:"SELF_OPERATION",
      visible: false,
      accountNamePrefix:"",
      startSeq:100,
      copyNum:"",
      btnLoading: false,
    };
  },
  computed: {

  },
  methods: {
    // 保存
    async handleOkD() {
      let obj = { copy_account_id:this.rowData.advertiserId,copy_number:this.copyNum,copy_name:this.accountNamePrefix,start_number:this.startSeq};
      let res = copyAccount(obj);
      this.$message.success('复制账号进行中,请稍后再进行查询！');
      this.copyNum='';
      this.accountNamePrefix='';
      this.startSeq='';
      this.visible = false;
    },

    async handleOk() {
      let obj = {reportType:this.reportType, copyAccountId:this.rowData.advertiserId,copyNumber:this.copyNum,copyName:this.accountNamePrefix,startNumber:this.startSeq};
      let res = copyAccount2(obj);
      this.$message.success('复制账号进行中,请稍后再进行查询！');
      this.copyNum='';
      this.accountNamePrefix='';
      this.startSeq='';
      this.visible = false;
    },

    // 用来清空数据，避免下次打开时有残留数据
    clearData() {
      this.copyNum='';
      this.accountNamePrefix='';
      this.startSeq=100;
      this.visible = false;
    },
  },
};
</script>

<style lang="less">
.edit_medai_account_modal {
  .ant-form {
    margin: 10px 0;
    height: 300px;
  }
}
</style>
