/*
 * @Author: chenxing
 * @Date: 2021-03-04 17:11:04
 * @LastEditors: Yran
 * @LastEditTime: 2022-01-26 11:06:33
 */
import axios from '@/utils/axios';

const apiUrl = {
  prjectDistribution: '/sys/projectRelateion/saveOrUpdate',
  userList: '/sys/user/list',
  projectList: '/sys/project/getListByChannelType',
  projectOperater: '/sys/projectRelateion/getMediaAccountList',
  getAdvertiserProjectList: '/sys/project/getListByAdvertiserId',
  editMediaAccount: '/sys/projectRelateion/updateTransfer',
  getCustomersInvoiceList: '/sys/customersInvoice/getCustomersInvoiceList', //获取开户主体
  getCustomersInvoiceListFromMda: '/sys/customersInvoice/getCustomersInvoiceListFromMda', //获取开户主体
  getFacilitatorList: '/agent/getProviderList ', //获取服务商
  // 服务商
  addAgent: '/agent/save',
  updateAgent: '/agent/update',
  getAgentList: '/agent/getList',
  deleteAgent: '/agent/delete',

  // 绑定、分配
  assignAccount: '/sys/projectRelateion/updateDistribute', // 媒体账号单个分配
  assignOperations: '/sys/projectRelateion/updateOperaters', // 媒体账号批量分配运营
  assignProducts: '/sys/projectRelateion/updateProjects', // 媒体账号批量分配产品
  getBindHistory: '/sys/projectRelateion/getOperaterAndProject', // 获取该媒体账号的绑定历史

  assignBusiness: '/sys/projectRelateion/updateIndustrys', // 媒体账号批量分配业务

  materialClearTaskCreate: '/clear/material/clear_task/create/', // 素材清理
  getClearMaterialTypesUrl: '/clear/material/clearMaterialTypes',

  clearMaterialMaterialStatus: '/clear/material/status/update/', //批量更新广告素材启用状态
  clearMaterialVideoPause: '/clear/material/video/pause/', // 批量暂停素材-不会暂停自动化工具下的素材
  mdaccountAccManagerList: '/sys/mediaAccount/getManagerAccountList',



  businessDeptBindUrl:'/sys/mediaAccount/businessDeptBind',//批量绑定业务模式
  agentAdvertiserRechargeFundExchangeApiUrl:'/extractor/tt/agent/advertiser/recharge/',//资金置换

  ttRefreshAccountUrl: '/extractor/tt/refresh/account/fire',
  gdtRefreshAccountUrl: '/extractor/gdt/refresh/account/fire',
  qcRefreshAccountUrl: '/extractor/qc/refresh/account/fire',
  activeAccountUrl: '/sys/mediaAccount/avtiveAccount',
  syncAccountDataInfo: '/extractor/tt/sync/accountInfo',

  ttPullDataUrl: '/extractor/tt/compensation/account_material',
  gdtPullDataUrl: '/extractor/gdt/compensation/account_material',
  qcPullDataUrl: '/extractor/qc/compensation/account_material',
  copyAccountUrl:'/copy/account',
  copyAccountUrl2:'/sys/mediaAccount/tt/copyAccount',
  getMaterialFileRebateMaterialSearchDataUrl:'/clear/material/file/rebate/material_search/',
  getMaterialFileRebateMaterialSearchDataUrlFromAdb:'/clear/material/file/rebate/material_search_adb/',

  ttPullSyncAdvLogUrl: '/extractor/tt/pull/adv/log',
};

export function getMaterialFileRebateMaterialSearchData(params) {
  return axios.post(apiUrl.getMaterialFileRebateMaterialSearchDataUrl, params);
}

export function getMaterialFileRebateMaterialSearchDataUrlFromAdb(params) {
  return axios.post(apiUrl.getMaterialFileRebateMaterialSearchDataUrlFromAdb, params);
}


export function pullAccountMaterialData(mediaChannel,params) {
  let url;
  if(mediaChannel==='TT'){
    url = apiUrl.ttPullDataUrl;
  }else if(mediaChannel==='GDT'){
    url = apiUrl.gdtPullDataUrl;
  }else if(mediaChannel==='QC'){
    url = apiUrl.qcPullDataUrl;
  }
  return axios.post(url, params);
}
export function pullSyncAdvLog(mediaChannel,params) {
  let url;
  if(mediaChannel==='TT'){
    url = apiUrl.ttPullSyncAdvLogUrl;
  }
  return axios.post(url, params);
}
export function activeAccountData(channel,params) {
  return axios.post(apiUrl.activeAccountUrl + `/${channel}`, params);
}
export function syncAccountDataInfo(channel,params) {
  return axios.post(apiUrl.syncAccountDataInfo, params);
}

export function refreshAccount(mediaChannel,params) {
  let url;
  if(mediaChannel==='TT'){
    url = apiUrl.ttRefreshAccountUrl;
  }else if(mediaChannel==='GDT'){
    url = apiUrl.gdtRefreshAccountUrl;
  }else if(mediaChannel==='QC'){
    url = apiUrl.qcRefreshAccountUrl;
  }
  if(params){
    return axios.get(url + `?parentAdvertiseId=${params}`);
  }else {
    return axios.get(url );
  }
}

export function getClearMaterialTypes() {
  return axios.get(apiUrl.getClearMaterialTypesUrl);
}

export function getManagerAccountListData(channel) {
  return axios.get(apiUrl.mdaccountAccManagerList + `/${channel}`);
}

export function getMediaAccountListData(params) {
  return axios.post(apiUrl.projectOperater, params);
}

export function confirmPrjectDistribution(params) {
  return axios.post(apiUrl.prjectDistribution, params);
}

export function getUserList(params) {
  return axios.post(apiUrl.userList, params);
}

export function getProjectOperater(projectCode) {
  return axios.get(apiUrl.projectOperater + `/${projectCode}`);
}

export function getAdvertiserProjectListData(params) {
  return axios.post(apiUrl.getAdvertiserProjectList, params);
}

export function getProjectListData(params) {
  return axios.post(apiUrl.projectList, params);
}

export function editMediaAccount(params) {
  return axios.post(apiUrl.editMediaAccount, params);
}
export function getCustomersInvoiceList(params) {
  return axios.post(apiUrl.getCustomersInvoiceList, params);
}
export function getCustomersInvoiceListFromMda(params) {
  return axios.post(apiUrl.getCustomersInvoiceListFromMda, params);
}
export function getFacilitatorListData(params) {
  //获取服务商列表
  return axios.post(apiUrl.getFacilitatorList, params);
}

export function addAgent(params) {
  //保存二级代理商基础信息信息
  return axios.post(apiUrl.addAgent, params);
}
export function updateAgent(params) {
  //修改二级代理商基础信息信息
  return axios.post(apiUrl.updateAgent, params);
}
export function getAgentList() {
  //获取二级代理商基础信息所有数据
  return axios.get(apiUrl.getAgentList);
}
export function deleteAgent(params) {
  //删除二级代理商基础信息信息
  return axios.post(apiUrl.deleteAgent, params);
}

// ------------------------------批量分配及单个分配-----------------------------------//
export function assignAccount(params) {
  return axios.post(apiUrl.assignAccount, params);
}
export function assignOperations(params) {
  return axios.post(apiUrl.assignOperations, params);
}
export function assignProducts(params) {
  return axios.post(apiUrl.assignProducts, params);
}
export function getBindHistory(params) {
  return axios.get(`${apiUrl.getBindHistory}/${params.advertiserId}`, params);
}
export function assignBusiness(params) {
  return axios.post(apiUrl.assignBusiness, params);
}

export function materialClearTaskCreate(params) {
  return axios.post(apiUrl.materialClearTaskCreate, params);
}

export function clearMaterialMaterialStatus(params) {
  return axios.post(apiUrl.clearMaterialMaterialStatus, params);
}

export function clearMaterialVideoPause(params) {
  return axios.post(apiUrl.clearMaterialVideoPause, params);
}

export function businessDeptBindApi(params) {
  return axios.post(apiUrl.businessDeptBindUrl, params);
}

export function agentAdvertiserRechargeFundExchangeApi(params) {
  return axios.post(apiUrl.agentAdvertiserRechargeFundExchangeApiUrl, params);
}


export function copyAccount(params) {
  let url = apiUrl.copyAccountUrl + `?copy_account_id=${params.copy_account_id}&copy_number=${params.copy_number}&copy_name=${params.copy_name}&start_number=${params.start_number}`
  return axios.get(url).then(res=>{
    console.log(res);
  }).catch(err=>{
    console.log(err);
  });
}


export function copyAccount2(params) {
  let url = apiUrl.copyAccountUrl2
  return axios.post(url,params).then(res=>{
    console.log(res);
  }).catch(err=>{
    console.log(err);
  });
}
